<script lang="ts">
	import ExpandableIcon from '$components/atoms/ExpandableIcon.svelte';
	import TwitterIcon from '$lib/icons/social-media/twitter-icon.svelte';
	import YoutubeIcon from '$lib/icons/social-media/youtube-icon.svelte';
	import FacebookIcon from '$lib/icons/social-media/facebook-icon.svelte';
	import InstagramIcon from '$lib/icons/social-media/instagram-icon.svelte';

	import { SOCIALS_CONFIG } from '$lib/constants/socials';
	const SOCIALS = [
		{
			name: 'Twitter',
			href: SOCIALS_CONFIG['twitter'],
			Icon: TwitterIcon
		},
		{
			name: 'Youtube',
			href: SOCIALS_CONFIG['youtube'],
			Icon: YoutubeIcon
		},
		{
			name: 'Facebook',
			href: SOCIALS_CONFIG['facebook'],
			Icon: FacebookIcon
		},
		{
			name: 'Instagram',
			href: SOCIALS_CONFIG['instagram'],
			Icon: InstagramIcon
		}
	];
</script>

<div class="flex items-center justify-start space-x-4">
	{#each SOCIALS as social}
		<ExpandableIcon
			Icon={social.Icon}
			title={social.name}
			href={social.href}
			isExpandable={false}
		/>
	{/each}
</div>
