<!-- 
This ExpandableIcon component provides an expandable button that can contain an icon and optional text content.

Props:
  - Icon: (Optional) A Svelte component to be used as the button's icon.
  - title: (Optional) A string to be used as the button's title. Default is an empty string.
  - href: (Optional) A string to be used as the social's href. Default is an empty string.
  - text: (Optional) A string to be used as the social's text. Default is an empty string.
	- isExpandable: (Optional) Boolean to be used to disable the expandable functionality. Default is true.

Usage:
  <ExpandableIcon
   Icon={SomeIconComponent}
   title="Some title"
   href="https://some-link.com"
   text="Some text"
	 isExpandable={true}
 />

Figma: https://www.figma.com/file/D5MF2f1c91r3tN0ymbKjng/89Transfers-V3-2023?node-id=1948-137429&t=JYv5Z4bCBbh4reiN-4
-->

<script context="module" lang="ts">
	import { writable, type Writable } from 'svelte/store';

	let activeContact: Writable<number> = writable(0);
	let id = 1;
</script>

<script lang="ts">
	import { onMount, type SvelteComponent } from 'svelte';
	import { isMobileDevice } from '$utils';
	import { slide } from 'svelte/transition';
	import { cubicOut } from 'svelte/easing';

	export let Icon: typeof SvelteComponent<any>;
	export let title = '';
	export let href = '';
	export let text = '';
	export let isExpandable = true;
	const componentId = id++;
	$: active = $activeContact === componentId;

	let isMobile = false;
	// this function is used to close the button when the user clicks on icon or non-link text
	const handleClick = (e: MouseEvent) => {
		e.stopPropagation();
		if (isMobile || !isExpandable) {
			window.open(href, '_blank');
			return;
		}
		const targetElement = e.target as Element;
		if (!(targetElement.tagName === 'A' || targetElement.closest('a'))) {
			$activeContact = active ? 0 : componentId;
		}
	};
	onMount(() => {
		isMobile = isMobileDevice();
	});
</script>

<button
	class="bg-linear-purple-50 flex-center transition-max-width duration-600 flex-center h-10 w-10 cursor-pointer rounded-full"
	class:w-auto={active}
	class:px-2={active}
	on:click={handleClick}
	aria-label="Socials"
	aria-expanded={active}
	{title}
>
	{#if Icon}
		{#if !isMobile}
			<div class="flex-center h-auto w-auto">
				<svelte:component this={Icon} />
			</div>
		{:else}
			<a class="flex-center h-auto w-auto hover:underline" {href} aria-label="Socials">
				<svelte:component this={Icon} />
			</a>
		{/if}
	{/if}

	<div
		class="transition-max-width max-w-0 overflow-hidden duration-300 ease-in-out"
		class:max-w-60={active}
		class:mx-2={active}
		out:slide|global={{
			duration: 300,
			easing: cubicOut
		}}
		in:slide|global={{
			delay: 600,
			duration: 300,
			easing: cubicOut
		}}
	>
		{#if active}
			<div class="text whitespace-nowrap">
				<a {href} class="hover:underline focus:underline" aria-label="Socials">
					{text}
				</a>
			</div>
		{/if}
	</div>
</button>
