export const isMobileDevice = () => {
	if (typeof window === 'undefined' || typeof navigator === 'undefined') {
		console.warn('isMobileDevice() should only be used in a client-side environment.');
		return false;
	}

	const userAgent = navigator.userAgent;
	const hasMobileUserAgent =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
			userAgent
		);
	const hasTouchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
	const isSmallScreen = window.innerWidth <= 768;
	return hasMobileUserAgent && isSmallScreen && hasTouchSupport;
};
