<script lang="ts">
	import SmallLogo from '$assets/small-logo.webp';
	import Contacts from '$components/footer/Contacts.svelte';
	import Socials from './Socials.svelte';
	import {
		LINK_ABOUT,
		LINK_BOOKING,
		LINK_CONTACT,
		LINK_FAQS,
		LINK_ROOT,
		LINK_PRIVACY_POLICY,
		LINK_TERMS_AND_CONDITIONS,
		LOCALIZELINK
	} from '$utils/link';
	import { getContext } from 'svelte';
	import type { LANGUAGE } from '$lib/constants/locale';
	let lang = getContext('lang') as LANGUAGE;
	let t = getContext('page-contents-landing') as Record<string, string>;

	let currentYear = new Date().getFullYear();
</script>

<div class="bg-grey-800 dark:bg-grey-700 block flex flex-col gap-y-10 px-3 pb-10 pt-20 md:hidden">
	<div class="mx-auto w-full max-w-[1120px]">
		<!-- Logo and pages -->
		<div class="flex flex-row flex-nowrap items-end justify-between">
			<a href={LOCALIZELINK(lang, LINK_ROOT)} aria-label="89transfers home page">
				<img src={SmallLogo} alt="small logo" loading="lazy" width="85px" height="70px" />
			</a>
			<div
				class="text-grey-100 rubik-regular ls--0.5px grid grid-cols-2 gap-x-[26px] gap-y-3 leading-5"
			>
				<a href={LOCALIZELINK(lang, LINK_BOOKING)} aria-label="89transfers Booking"
					>{t.menu_booking}</a
				>
				<a href={LOCALIZELINK(lang, LINK_FAQS)} aria-label="89transfers faq">{t.menu_faqs}</a>
				<a href={LOCALIZELINK(lang, LINK_ABOUT)} aria-label="about 89transfers">{t.menu_about}</a>
				<a href={LOCALIZELINK(lang, LINK_CONTACT)} aria-label="contact 89transfers"
					>{t.menu_contact}</a
				>
			</div>
		</div>
		<!-- Contacts -->
		<div class="my-2 flex flex-col gap-y-3">
			<p class="text-grey-100 rubik-regular ls--0.5px leading-5">{t.footer_contacts}</p>
			<Contacts />
		</div>
		<!-- Follow us -->
		<div class="my-3 flex flex-col gap-y-3">
			<p class="text-grey-100 rubik-regular ls--0.5px leading-5">{t.footer_follow_us}</p>
			<Socials />
		</div>
		<!-- Copyright -->
		<div
			class="rubik-regular ls--0.005em text-grey-300 rubik-regular flex flex-row flex-nowrap justify-between gap-3 text-[10px] leading-5"
		>
			<p>{t.footer_copyright} &copy;{currentYear} 89transfers</p>
			<p>{t.footer_all_rights_reserverd}</p>
			<a
				href={LOCALIZELINK(lang, LINK_TERMS_AND_CONDITIONS)}
				aria-label="Read 89transfers terms and conditions">{t.menu_terms_and_conditions}</a
			>
		</div>
	</div>
</div>
<div class="bg-grey-800 dark:bg-grey-700 flex !hidden flex-col gap-y-10 px-3 pb-10 pt-20 md:!block">
	<div class="mx-auto w-full max-w-[1120px]">
		<!-- Logo and pages -->
		<div class="flex flex-row flex-nowrap items-end justify-between gap-3">
			<a
				href={LOCALIZELINK(lang, LINK_ROOT)}
				aria-label="89transfers home page"
				class="flex-shrink-0"
			>
				<img src={SmallLogo} alt="small logo" width="95px" height="78px" loading="lazy" />
			</a>
			<div class="flex w-full max-w-[426px] flex-col gap-y-3">
				<div
					class="rubik-regular ls--0.005em text-grey-300 rubik-regular flex max-w-[426px] flex-row justify-between gap-3 text-[10px] leading-5"
				>
					<p>{t.footer_copyright} &copy;{currentYear} 89transfers</p>
					<p>{t.footer_all_rights_reserverd}</p>
					<a
						href={LOCALIZELINK(lang, LINK_PRIVACY_POLICY)}
						aria-label="Read 89transfers privacy policy"
					>
						{t.menu_privacy_policy}
					</a>
					<a
						href={LOCALIZELINK(lang, LINK_TERMS_AND_CONDITIONS)}
						aria-label="Read 89transfers terms and conditions">{t.menu_terms_and_conditions}</a
					>
				</div>
				<div class="flex flex-row gap-x-3">
					<Contacts />
					<Socials />
				</div>
			</div>
			<div
				class="text-grey-100 rubik-regular ls--0.5px gap-x-26px flex flex-row flex-nowrap justify-between self-start whitespace-nowrap leading-5"
			>
				<a href={LOCALIZELINK(lang, LINK_BOOKING)} aria-label="89transfers Booking"
					>{t.menu_booking}</a
				>
				<a href={LOCALIZELINK(lang, LINK_FAQS)} aria-label="89transfers faq">{t.menu_faqs}</a>
				<a href={LOCALIZELINK(lang, LINK_ABOUT)} aria-label="about 89transfers">{t.menu_about}</a>
				<a href={LOCALIZELINK(lang, LINK_CONTACT)} aria-label="contact 89transfers"
					>{t.menu_contact}</a
				>
			</div>
		</div>
	</div>
</div>
