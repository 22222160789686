<script lang="ts">
	import ExpandableIcon from '$components/atoms/ExpandableIcon.svelte';
	import PhoneIcon from '$lib/icons/social-media/phone-icon.svelte';
	import EmailIcon from '$lib/icons/social-media/email-icon.svelte';
	import WhatsappIcon from '$lib/icons/social-media/whatsapp-icon.svelte';
	import { SOCIALS_CONFIG } from '$lib/constants/socials';
	const SOCIALS = [
		{
			name: 'Phone',
			href: `tel:${SOCIALS_CONFIG['phone']}`,
			text: SOCIALS_CONFIG['phone'],
			Icon: PhoneIcon
		},
		{
			name: 'Email',
			href: `mailto:${SOCIALS_CONFIG['email']}`,
			text: SOCIALS_CONFIG['email'],
			Icon: EmailIcon
		},
		{
			name: 'Whatsapp',
			text: SOCIALS_CONFIG['phone'],
			href: SOCIALS_CONFIG['whatsapp'],
			Icon: WhatsappIcon
		}
	];
</script>

<div class="flex items-center justify-start space-x-3">
	{#each SOCIALS as social}
		<ExpandableIcon Icon={social.Icon} title={social.name} href={social.href} text={social.text} />
	{/each}
</div>
